<template>
  <the-navigation></the-navigation>
  <div class="container py-10 mx-auto report" id="print-section">
    <h1 class="font-bold text-md">READING & UNDERSTANDING THE REPORT</h1>
    <div class="intro-info py-5">
      Welcome to better self-awareness! This Basic BRET (Behavioral Response and
      Engagement Test) report has been generated based on your responses to the
      Assessment. We hope that, while taking this assessment, you provided your
      responses in an unbiased and objective manner. This report gives a
      fundamental understanding of your behavioural pattern and may explain some
      of the choices or your response to certain triggers. You may be able to
      identify and understand your behavioural approach. This can help you guard
      against some of your actions that may not produce best results for you.
      You may also be able to identify behaviours that may work for you.
      However, a more personalized understanding you need a Professional BRET
      Report. To be able to identify your core strength areas as well as areas
      that may need your attention for development given your professional and
      personal aspirations you would need a Premium BRET Report. You may seek
      guidance from our experts to make the best use of this report. <br/>Please
      reach out to us at <a href="mailto:bretreport@preceptleadership.com" class="text-secondary underline">bretreport@preceptleadership.com</a> for detailed
      information
      
    </div>
    <div class="definition_item mt-10">
      <h1 class="font-bold text-base">DEFINITIONS: Natural Behavioral Style (Based on Drives/Motivators)</h1>
      <div class="intro-info py-5">
        <h2 class="text-sm font-bold text-secondary">1. Altruistic Drive:</h2>
        <ul class="list-disc px-5">
          <li>Leads to action and behavior that may not provide rational or logical explanations in terms of the benefits that may accrue to the person</li>
          <li>Prompts actions that are more about intangible benefits and benefits for a larger group of people/society at large</li>
          <li>Such actions are often at the cost of one’s own comfort and benefit</li>
          <li>No relation with religiosity, but in its extreme and absolute form it may create a Gautam Buddha or a Mother Teressa.</li>
        </ul>
      </div>
      <div class="intro-info py-5">
        <h2 class="text-sm font-bold text-secondary">2. Emotional Drive:</h2>
        <ul class="list-disc px-5">
          <li>Leads to action and behavior that may not provide rational or logical explanations in terms of the benefits that may accrue to the person</li>
          <li>Prompts actions that are more about intangible benefits and benefits for a larger group of people/society at large</li>
          <li>Such actions are often at the cost of one’s own comfort and benefit</li>
          <li>No relation with religiosity, but in its extreme and absolute form it may create a Gautam Buddha or a Mother Teressa.</li>
        </ul>
      </div>
      <div class="intro-info py-5">
        <h2 class="text-sm font-bold text-secondary">3. Power Drive:</h2>
        <ul class="list-disc px-5">
          <li>Leads to action and behavior that may not provide rational or logical explanations in terms of the benefits that may accrue to the person</li>
          <li>Prompts actions that are more about intangible benefits and benefits for a larger group of people/society at large</li>
          <li>Such actions are often at the cost of one’s own comfort and benefit</li>
          <li>No relation with religiosity, but in its extreme and absolute form it may create a Gautam Buddha or a Mother Teressa.</li>
        </ul>
      </div>
      <div class="intro-info py-5">
        <h2 class="text-sm font-bold text-secondary">4. Existential Drive:</h2>
        <ul class="list-disc px-5">
          <li>Leads to action and behavior that may not provide rational or logical explanations in terms of the benefits that may accrue to the person</li>
          <li>Prompts actions that are more about intangible benefits and benefits for a larger group of people/society at large</li>
          <li>Such actions are often at the cost of one’s own comfort and benefit</li>
          <li>No relation with religiosity, but in its extreme and absolute form it may create a Gautam Buddha or a Mother Teressa.</li>
        </ul>
      </div>
      <div class="graph_body w-full">
        <div class="title font-bold mx-auto text-center">Your Natural Behavioral Style Graph - Motivators</div>
        <BarChart v-if="chartDatas" class="graph mx-auto" :chartData="graph_a" />
      </div>
    </div>


    <div class="definition_item mt-10">
      <h1 class="font-bold text-base">DEFINITIONS: Conditioned Behavioral Style (Based on Change Orientation)</h1>
      <div class="intro-info py-5">
        <h2 class="text-sm font-bold text-secondary">1. Innovator:</h2>
        <ul class="list-disc px-5">
          <li>Tends to find new ways of doing things</li>
          <li>Experiments and seeks improvement in all activities</li>
          <li>Will demonstrate a tendency to take risks</li>
          <li>Moves quickly</li>
          <li>Enjoys multi-tasking</li>
          <li>Impatient with details and routine tasks</li>
          <li>Demonstrates competitiveness</li>
        </ul>
      </div>
      <div class="intro-info py-5">
        <h2 class="text-sm font-bold text-secondary">2. Adopter:</h2>
        <ul class="list-disc px-5">
          <li>Identifies the need to change</li>
          <li>Eager to try out new ideas seeks to find out developments in related areas</li>
          <li>Moderate risk takers</li>
          <li>Prefers to work in a collaborative environment</li>
          <li>Moderate risk takers</li>
          <li>Seeks continuous improvement rather than drastic change</li>
        </ul>
      </div>
      <div class="intro-info py-5">
        <h2 class="text-sm font-bold text-secondary">3. Evaluator:</h2>
        <ul class="list-disc px-5">
          <li>Cautious with change</li>
          <li>Strong detail orientation</li>
          <li>Checks the results and would want to minimize risks</li>
          <li>Strong analytical skills</li>
          <li>Thrives in stable predictable environment</li>
          <li>Well organized and dependable</li>
        </ul>
      </div>
      <div class="intro-info py-5">
        <h2 class="text-sm font-bold text-secondary">4. Preserver:</h2>
        <ul class="list-disc px-5">
          <li>Not comfortable with change</li>
          <li>Needs time to adapt to any new idea or concept</li>
          <li>Needs a standard approach for all activities</li>
          <li>Derailed by unpredictable or volatile environment</li>
          <li>Doesn’t like surprises</li>
          <li>Risk tolerance tending to zero</li>
        </ul>
      </div>
      <div class="graph_body w-full">
        <div class="title font-bold mx-auto text-center">Your Natural Behavioral Style Graph - Change Orientation</div>
        <BarChart v-if="chartDatas" class="graph mx-auto" :chartData="graph_b" />
      </div>
    </div>


    <div class="definition_item mt-10">
      <h1 class="font-bold text-base">DEFINITIONS: Natural Behavioral Style (Based on Drives/Motivators)</h1>
      <div class="intro-info py-5">
        <h2 class="text-sm font-bold text-secondary">1. Authoritative:</h2>
        <ul class="list-disc px-5">
          <li>Commanding, focused on exercising authority, my wa or the high way</li>
          <li>Reward punishment, sharp, crisp messages</li>
          <li>Poor listening skills</li>
          <li>Builds relationships with a condescending attitude</li>
          <li>Assumes and exercises control in case of ambiguity</li>
          <li>Oversteps authority</li>
          <li>May define or defy processes and structures on the go depending on his/her perception of the situation</li>
          <li>Focused on tasks and results</li>
        </ul>
      </div>
      <div class="intro-info py-5">
        <h2 class="text-sm font-bold text-secondary">2. Directive:</h2>
        <ul class="list-disc px-5">
            <li>Focused on systems and processes</li>
            <li>Strong attention to details. Likes following norms and system</li>
            <li>Struggles in chaotic situation</li>
            <li>Strong need for structure and work process</li>
            <li>Defines and likes adherence to rule, comfortable in wielding authority to the extent it is defined</li>
            <li>Task completion as per standards is importance</li>
            <li>Demonstrations a hands on approach</li>
            <li>Sets standards of conduct and expects adherence</li>
            <li>Focuses on accuracy and quality</li>
        </ul>
      </div>
      <div class="intro-info py-5">
        <h2 class="text-sm font-bold text-secondary">3. Emotive:</h2>
        <ul class="list-disc px-5">
            <li>Usually uses emotions to get the ideas across</li>
            <li>Quiet and soft approach to dealing with dissent or disagreement</li>
            <li>Non-combative. Strives for consensus in all situations</li>
            <li>May appear indecisive or slow in challenging environment</li>
            <li>Uses personal connect and relationships to get the task completed.</li>
        </ul>
      </div>
      <div class="intro-info py-5">
        <h2 class="text-sm font-bold text-secondary">4. Placative:</h2>
        <ul class="list-disc px-5">
            <li>Would often appear to be uncomfortable with wielding power</li>
            <li>Seeks to ensure the comfort and happiness of all team members</li>
            <li>Avoids conflicts and disagreements.</li>
            <li>Struggles with difficult and disciplinary conversations</li>
            <li>May struggle to communicate newer approach to work</li>
            <li>Concerned about the response/reaction of people rather than the benefits or fall out of change.</li>
        </ul>
      </div>
      <div class="graph_body w-full">
        <div class="title font-bold mx-auto text-center">Your Natural Behavioral Style Graph - Communication Style</div>
        <BarChart v-if="chartDatas" class="graph mx-auto" :chartData="graph_c" />
      </div>
    </div>

    <!-- save report btn -->
    <!-- <div class="flex justify-center mt-10">
      <button
        class="bg-primary
        text-white
        font-bold
        py-2
        px-4
      "
        @click="saveReport"
      >
        Save Report
      </button>
    </div> -->


    <!-- <div class="m-10 flex flex-row" v-if="chartDatas">
      <div
        v-for="item in chartDatas"
        :key="item.id"
        class="flex-auto w-10 gap-10"
      >
        <BarChart :chartData="item" />
      </div>
    </div> -->
    <div v-if="comment">
      <h2 class="font-bold">Comment:</h2>
      <p>{{ comment }}</p>
    </div>
  </div>
</template>

<script>
import BarChart from "../components/BarChart.vue";
import CryptoJS from "crypto-js";
import TheNavigation from "../components/TheNavigation.vue";

export default {
  components: { BarChart, TheNavigation },
  name: "TheResults",
  data() {
    return {
      results: [],
      chartDatas: [],
      quiztaker_id: "",
      comment: "",
      graph_a: [],
      graph_b: [],
      graph_c: [],
    };
  },
  methods: {
    // generate random rgba colors on white background
    random_rgba() {
      var o = Math.round,
        r = Math.random,
        s = 255;
      return (
        "rgba(" + o(r() * s) + "," + o(r() * s) + "," + o(r() * s) + ", 0.8)"
      );
    },

    initGraph() {
      // fetch results from results store
      this.results = this.$store.state.quiz.results[0];
      this.comment = this.$store.state.quiz.comment;
      let res_keys = Object.keys(this.results);

      // loop through results using keys
      for (let i = 0, len = res_keys.length; i < len; i++) {
        let key = res_keys[i];
        let value = this.results[key];
        let bg = this.random_rgba();
        this.chartDatas.push({
          labels: Object.keys(value.data),
          datasets: [
            {
              label: "# Percentage",
              data: Object.values(value.data),
              backgroundColor: [bg, bg, bg, bg],
              borderColor: [bg, bg, bg, bg],
              borderWidth: 1,
            },
          ],
        });
      }

      this.graph_a = this.chartDatas[0];
      this.graph_b = this.chartDatas[1];
      this.graph_c = this.chartDatas[2];
    },

    checkParams() {
      console.log(this.$store.state.user);
      // if route has params
      if (this.$route.query.share_code) {
        var encrypted = this.$route.query.share_code; //python is base64 ECB
        var key = "AAAAAAAAAAAAAAAA"; //key used in Python
        key = CryptoJS.enc.Utf8.parse(key);
        var decrypted = CryptoJS.AES.decrypt(encrypted, key, {
          mode: CryptoJS.mode.ECB,
        });
        let data = decrypted.toString(CryptoJS.enc.Utf8);
        // get value of quiztaker data
        let quiztaker = data.split("=")[1];
        this.quiztaker_id = quiztaker;
      } else if (this.$store.state.user.quizTaker.id) {
        this.quiztaker_id = this.$store.state.user.quizTaker.id;
      } else {
        this.$router.push("/");
      }
    },

    getResults() {
      this.$store.dispatch("getResults", this.quiztaker_id);
    },

    // print report div to pdf
    saveReport() {
      let printContents, popupWin;
      printContents = document.getElementById("print-section").innerHTML;
      popupWin = window.open("", "_blank", "top=0,left=0,height=100%,width=auto");
      popupWin.document.open();
      popupWin.document.write(`
        <html>
          <head>
            <title>Print tab</title>
            <style>
              //........Customized style.......
            </style>
          </head>
          <body onload="window.print();window.close()">${printContents}</body>
        </html>`
      );
      popupWin.document.close();
    },
  },
  mounted() {
    // check params then get results
    this.checkParams();
    this.getResults();
    this.initGraph();
  },
};
</script>

<style scoped>
.graph {
  max-width: 500px;
  height: 100%;
}
</style>